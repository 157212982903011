import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "submitting-claims",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#submitting-claims",
        "aria-label": "submitting claims permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Submitting claims`}</h1>
    <p>{`The submitting claims section consists of the following three sections:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#eligibility-checks-and-quotes"
        }}>{`Eligibility checks and quotes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#provider-and-location-details"
        }}>{`Provider and location details`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#patients"
        }}>{`Patients`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#items"
        }}>{`Items`}</a></li>
    </ul>
    <h4 {...{
      "id": "eligibility-checks-and-quotes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#eligibility-checks-and-quotes",
        "aria-label": "eligibility checks and quotes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Eligibility checks and quotes`}</h4>
    <p>{`Quotes and claims can be implemented as separate actions from the PMS by setting the isQuote property within funderData to either true for quote-only or false for claim-only mode. Alternatively, the SDK can be rendered with a toggle that allows the user to select between submitting a quote or a claim by omitting `}<strong parentName="p">{`isQuote`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassPartnerSDK.renderCreateTransaction({
  platform: "funder",
  funder: "wsv",
  funderData: {
    wsv: {
      isQuote: true,
    }
  }
}, {
  version: "3"
});

`}</code></pre>
    <p>{`On the quote modal, which is displayed after the quote has been submitted to WSV, there are three buttons available of which two are configurable. The “Dismiss quote” button that closes the quote modal is not configurable.  The “Edit quote” button returns the user to the quote to make changes, while the “Submit as claim” button converts the quote to a claim and submits it to WorkSafe Vic, eliminating the need to re-key information. Both of these buttons are configurable, and may be displayed or hidden depending on what best suits the workflows in your software.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassPartnerSDK.renderCreateTransaction({
  platform: "funder",
  funder: "wsv",
  funderData: {
    wsv: {
      isQuote: true,
      enableEditQuote: true,
      enableSubmitQuoteAsClaim: true
    }
  }
}, {
  version: "3"
});

`}</code></pre>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h4 {...{
      "id": "provider-and-location-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#provider-and-location-details",
        "aria-label": "provider and location details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Provider and location details`}</h4>
    <p>{`Provider and practice location details can be set with a single `}<strong parentName="p">{`providerNumber`}</strong>{` attribute. Importantly, this provider number must be defined and active in Tyro Health and for the given business API Key. Provider numbers which are not registered in Tyro Health or for the given business API key will error. Only one provider can claim per invoice.`}</p>
    <br />Provider number formats can vary by professional category and issuing organisation.  In general, the provider number will be defined as follows: 
    <br />
    <br />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Professional category`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Issued to`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Issuing body`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Format`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Examples`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`General practitioner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Individual, unique for `}<br />{` each location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medicare`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NNNNNNAA `}<br />{` Up to 8 characters `}<br />{` Note: leading zeros can be omitted `}<br />{` Details on Medicare provider numbers `}<br /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2429591L `}<br />{` 0012341A `}<br />{`12341A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Physiotherapy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Individual, unique for `}<br />{` each location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medicare`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NNNNNNAA `}<br />{` Up to 8 characters `}<br />{` Note: leading zeros can be omitted `}<br />{` Details on Medicare provider numbers `}<br /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2429591L `}<br />{` 0012341A `}<br />{`12341A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pharmacy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Pharmacy, unique for `}<br />{` each location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Worksafe Victoria`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NNNNNNAA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PH0060`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`For the SDK, if a provider number is not sent in the claim payload, the the user will be prompted to manually select:`}</p>
    <ul>
      <li parentName="ul">{`The practice location`}</li>
      <li parentName="ul">{`Individual provider`}</li>
      <li parentName="ul">{`Provider profession type and/or specialist category `}<br /></li>
    </ul>
    <p>If only one of each category has been defined, that will be automatically selected.</p> 
    <h5 {...{
      "id": "registration-of-provider-numbers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#registration-of-provider-numbers",
        "aria-label": "registration of provider numbers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Registration of provider numbers`}</h5>
    <p>{`Providers must first register with Tyro Health prior to submitting a WorkSafe Victoria claim. We do not currently offer an API for partners to self-register providers.`}<br /></p>
    <p>{`This registration process is self-service and can be completed within a few minutes. If providers deliver services from multiple locations or businesses, each unique provider number must be registered. Details on provider registration and WorkSafe Victoria activation is provided at:
`}<a parentName="p" {...{
        "href": "https://help.medipass.com.au/en/articles/5834449-enable-worksafe-victoria-digital-invoicing"
      }}>{`https://help.medipass.com.au/en/articles/5834449-enable-worksafe-victoria-digital-invoicing`}</a></p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h4 {...{
      "id": "patients",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#patients",
        "aria-label": "patients permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patients`}</h4>
    <p>{`Patients
For WorkSafe Victoria, a claim requires one valid patient/injured worker. Multiple patients are not supported - each must be submitted as a separate claim. A claim requires:`}<br /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`firstName`}</strong>{`: which can be up to 40 characters, and only contain alpha (A-Z and a-z), numeric (0- 9), space ( ), apostrophe (') and hyphen (- ) characters. Spaces must not appear before or after apostrophes, hyphens, other spaces or the supplied value.  At least one alpha or numeric character required.  If the patient has only one name, set that in the lastName field and set firstName to “onlyname”. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`lastName`}</strong>{`: which can be up to 40 characters,  and only contain alpha (A-Z and a-z), numeric (0- 9), space ( ), apostrophe (') and hyphen (- ) characters. Spaces must not appear before or after apostrophes, hyphens, other spaces or the supplied value.  At least one alpha or numeric character required. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`dob`}</strong>{`: Date of birth  in YYYY-MM-DD format. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`accountNumber`}</strong>{`: should be set to the patient claim number, which is numeric and typically 12 digits long. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`refId`}</strong>{` (optional): An optional but highly recommended patient unique reference as set by you, such as a UUID/GUID. This value is used to uniquely identify a patient in the Tyro Health platform. Omitting refId will cause duplicate patient records to be created in Tyro Health and could make patient searches more difficult for providers who also use the Tyro Health portal to check on status of a claim and remittance. If supplied and valid, this value will pre-populate patient details from the patient record in the business as set in any prior claim. Any patient details set for an existing patient record will update those values and override what was previously stored. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`mobile`}</strong>{` (optional): patient mobile number either in international or national notation and used for uniquely searching for patients in the Tyro Health provider portal. `}<br /></li>
    </ul>
    <p>{`Eligibility checks prior to claim submission are not currently supported. However, patient details will be validated at time of claim submission and invalid details will be flagged with corresponding error details. `}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h4 {...{
      "id": "items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#items",
        "aria-label": "items permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Items`}</h4>
    <p>{`Over 10,000 unique item codes - also referred to as service items, item numbers, material numbers or pay codes - are supported by Tyro Health for WWorkSafe Victoria claims. This includes most medical provider items defined in the Medicare Benefits Schedule (MBS), Pharmaceuticals as defined in the PBS and others as defined by WorkSafe Victoria. Multiple service items with different dates of service can be included in a single claim.`}<br />{` `}</p>
    <p>{`Multiple item code sources are supported by the WorkSafe Victoria integration. In general item types and item codes can be defined as follows:`}<br /></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Item type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Applicable professional categories`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`MBS`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medicare Benefits Scheme`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GP, Medical Specialists including pathology and diagnostic imaging`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Worksafe`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WorkSafe Victoria custom item codes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`OTC`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Over the counter Pharmacy items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pharmacy`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`PBS`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pharmacy items issued under PBS rules`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pharmacy`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Private`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controlled Pharmacy items issued as private scripts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pharmacy`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Item codes are updated dynamically by WorkSafe Victoria and new codes will be automatically integrated into the Tyro Health platform. Current WorkSafe Victoria item codes can be obtained via : `}<a parentName="p" {...{
        "href": "https://www.worksafe.vic.gov.au/information-for-providers"
      }}>{`https://www.worksafe.vic.gov.au/information-for-providers`}</a>{` `}</p>
    <p>{`WorkSafe Victoria allows providers to determine their own fees for services. However, payment will only be made up to a maximum benefit amount for each service. If the amount charged by a provider exceeds WorkSafe Victoria's maximum price for an item, a warning will be displayed, but the item can be submitted and adjudicated as-is. `}<br /></p>
    <p>{`Approved claims will return a “benefit paid”, and the remaining amount will appear as a “gap” on the invoice. The service provider is permitted to charge the injured worker additional gap fees for amounts not covered by WorkSafe Victoria.  `}<br /></p>
    <p>{`A valid claim will contain the following item attributes:`}<br /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`reference`}</strong>{` (optional): optional but recommended, a line item unique reference as nominated by you such as a UUID/GUID. Assists with reconciliation where multiple of the same item code and service date are set in a claim. Up to 128 alphanumeric characters are permitted which must be unique for each service item in a claim. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`serviceDateString`}</strong>{`: date of service in YYYY-MM-DD format. Past service dates - up to two years from submission date - are supported. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`itemCode`}</strong>{`: up to 12 characters. The item code must be applicable for a given service date and provider registered professional category. For MBS items, leading zeros are permitted or may be omitted. Items which are not known or invalid for a given service date or profession will be flagged with an error at the item level. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`price`}</strong>{`: net (excluding GST) price each unit in $XX.XX format. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`quantity`}</strong>{` (optional): by default, the quantity is 1. Quantity greater than 1 should only be used when the item unit of measure is Quantity Dispensed or for Kilometres. If the provider is charging for multiple items where the unit of measure is each, those should be listed as separate items rather than a single item with multiple quantity. `}<br /></li>
    </ul>
    <p>{`Units of measure vary depending on item code. The current supported WorkSafe Victoria units of measure include:`}<br /></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Unit of Measure`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Quantity`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Items`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Defaults to 1 for items. Quantity should not be set.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Quantity Dispensed`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pharmacy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Should be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Kilometres`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Physiotherapist`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Should be set`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul"><strong parentName="li">{`description`}</strong>{` (optional): a description of the item up to 128 characters. Only used and required for special catch-all items, such as AP001 - aids & appliances. If a description is required for the item and not provided, an error response will be returned at the item level. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`gstInclusiveFlag`}</strong>{` (optional): indicates if GST should be applied for the item. Note that services delivered to a patient are typically GST free. Services delivered for the insurer/agent may attract GST. Default is false. If set to true, GST of 10% will be added to the price amount. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`serviceTime`}</strong>{` (optional): time of service in HH:MM in a 24 hour cycle. Typically only set if duplicate items are charged for the same service date or after-hours items are charged. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`numberOfPatientsSeen`}</strong>{` (optional): only applicable for item codes which require the provider to state the number of patients seen, such as MBS items 4, 24, 37 or other group attendance items. `}<br /></li>
    </ul>
    <p>{`For Pharmacy items, the following item attributes apply when the item is dispensed as a private script. Pharmacy items dispensed under the PBS will not require these attributes. `}<br /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`prescriberFirstName`}</strong>{`: first name of the prescribing provider, such as a GP or specialist. Up to 40 characters. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`prescriberLastName`}</strong>{`: last name of the prescribing provider, such as a GP or specialist. Up to 40 characters. `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`prescriberNumber`}</strong>{`: typically the Medicare issued provider number of the prescribing GP or specialist.`}<br /></li>
      <li parentName="ul"><strong parentName="li">{`scriptNumber`}</strong>{`: prescribing provider script number.`}<br /></li>
      <li parentName="ul"><strong parentName="li">{`authorityNumber`}</strong>{`r: prescribing provider authority number.`}<br /></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      